 // email verification
form.verification {
    @include flex(row, end, center, wrap);
    height: 100%;

    article {
        @include flex(column, center, center);
        gap: 16px;
        width: 100%;
        position: relative;
        @include xl {
            gap: 0;
        }

        .form-invalid {
            color: $error;
            position: absolute;
            left: 0;
            bottom: 14px;
            @include xl {
                bottom: -15px;
            }
        }
    
        // @media only screen and (min-height: 700px) and (max-height: 900px) {
        //     height: calc(100% + 32px);
        // }
    }

    label {
        @include fontStyle($text-color, $middle-heading, 20px, 400);
    }

    span {
        @include fontStyle($grey-color, $small-heading, 16px, 400);
        position: relative;
        left: -125px;
        bottom: 57px;
        opacity: 0;
        transition: all 0.5s;

        @include xl {
            left: 17px !important;
            margin-top: 5px;
            bottom: 29px;
            position: absolute;
        }
    }

    p {
        @include fontStyle($error, $middle-heading, 20px, 400);

        &.hide {
            display: none;
        }
    }

    input {
        width: 100%;
        height: 52px;
        border: 1px solid $grey-color-second;
        border-radius: 8px;
        background: transparent;
        padding: 0 16px;
        @include fontStyle($text-color, $middle-heading, 20px, 700);
        margin-top: 16px;
        transition: all 0.5s;

        &:focus::placeholder {
            color: transparent;
        }

        &:focus {
            padding-top: 17px;
        }

        &:focus + span {
            opacity: 1;
        }

        &::placeholder {
            @include fontStyle($grey-color, $middle-heading, 20px, 400);
        }

        &.error {
            border: 1px solid $error;
            color: $error;
        }
    }
}

// code form
form.code {
    @include flex(row, end, center, wrap);
    height: 100%;

    article {
        @include flex(column, center, center);
        gap: 16px;
        width: 100%;
    
        @media only screen and (min-height: 700px) and (max-height: 900px) {
            // height: calc(100% + 32px);
        }

        label {
            @include fontStyle($text-color, $middle-heading, 20px, 400);
            text-align: center;
            display: block;
        }

        p {
            @include fontStyle($error, $middle-heading, 20px, 400);

            &.hide {
                display: none;
            }
        }

        span {
            @include fontStyle($text-color, $middle-heading, 20px, 400); 
        }

        a {
            @include fontStyle($primary-color, $middle-heading, 20px, 700);

            &.disabled {
                color: rgba(12, 25, 32, 0.24);
                pointer-events: none;
            }
        }

        .group-input {
            @include flex(row, center, center);
            gap: 10px;

            input {
                width: 40px;
                height: 60px;
                border: 1px solid $grey-color-second;
                border-radius: 10px;
                background: transparent;
                @include fontStyle($primary-color, $medium-heading, 20px, 700);
                text-align: center;
                transition: all 0.5s;

                &:focus,
                &.valid {
                    border: 1px solid $primary-color;
                }

                &.error {
                    border: 1px solid $error;
                    color: $error;
                }
            }
        }
    }
}

// upload form
form.upload {
    @include flex(row, end, center, wrap);
    height: 100%;

    article {
        @include flex(column, center, center);
        gap: 16px;
        width: 100%;

        label {
            @include flex(row, center, center);
            @include fontStyle($text-color, $middle-heading, 20px, 700);
            gap: 10px;
            width: 148px;
            height: 148px;
            border: 1px dashed #0C1920;
            border-radius: 50%;
            cursor: pointer;

            img {
                width: 20px;
            }

            input {
                display: none;
            }
        }
    }
}

// uploading form
form.uploading {
    @include flex(row, end, center, wrap);
    height: 100%;

    article {
        @include flex(column, center, center);
        gap: 16px;
        width: 100%;

        .progress {
            width: 100%;
            height: 6px;
            background: $main-white;
            border-radius: 5px;
            overflow: hidden;

            &>span {
                display: block;
                height: 6px;
                background: linear-gradient(90deg, #F8C30A 1%, #E26B85 49.47%, #50A4CD 100%);
                border-radius: 5px;
                transition: all 0.5s;
            }
        }

        .files-group {
            @include flex(row, center, space-between);
            gap: 10px;
            width: 100%;

            &>img {
                width: 40px;
                height: 40px;
                background: $second-color;
                border-radius: 50%;
                object-fit: none;
            }

            .files-info {
                @include flex(column);
                width: 77%;
                h2 {
                    @include fontStyle($text-color, $middle-heading, 20px, 400);
                }
                span {
                    @include fontStyle($grey-color, $small-heading, 16px, 400);
                }
            }

            .stop-upload {
                cursor: pointer;
                &>img {
                    width: 24px;
                }
            }
        }
    }
}

// generated link
form.generated {
    @include flex(row, flex-start, center, wrap);
    height: 100%;

    @include xl {
        height: calc(27rem - 65px);
    }

    @media only screen and (min-height: 700px) and (max-height: 900px) {
        height: calc(38rem - 20px);
    }

    @media only screen and (min-width: 1600px) {
        align-items: flex-start;
        height: calc(100% - 46px);
    }

    .input-wrapper {
        display: flex;
        width: 100%;
        height: calc(100% - 64px);
        flex-wrap: wrap;
        align-content: end;
        .form-group {
            position: relative;
            width: 100%;
            border-top: 1px solid $grey-color-second;
            &:last-child {
                border-bottom: 1px solid $grey-color-second;
            }
            &>input {
                width: 100%;
                height: 52px;
                background: #e3e7ec;
                padding: 0 8%;
                @include fontStyle($text-color, $middle-heading, 20px, 400);
                
                &::placeholder {
                    @include fontStyle($text-color, $middle-heading, 20px, 400);
                }
            }
            &>img {
                width: 24px;
                height: 24px;
                position: absolute;
                right: 7%;
                top: 14px;
            }
        }
    }

    .setting-btn {
        @include flex(row);
        gap: 16px;
        width: 100%;

        @include xl {
            flex-wrap: wrap;
        }

        &>button {
            width: 39%;
            &.full-btn {
                width: calc(100% - 60px);
            }

            @include xl {
                &:nth-child(2) {
                   
                    order: 1;
                }
                &:nth-child(1) {
                    order: -1;
                }
            }
        }

        .circle {
            width: 44px;
            padding-bottom: 8px;
        }
    }
    article {
        @include flex(column, center, center);
        gap: 16px;
        width: 100%;
        height: calc(100% - 45px);

        @include xl {
            height: calc(100% - 45px);
        }

        .files-group {
            width: 118%;
            height: 57vh;
            overflow-x: hidden;
            overflow-y: scroll;
            @media only screen and (max-height: 776px) {
                height: 53vh;
            }

            &>input {
                width: 95%;
                height: 52px;
                border-top: 1px solid $grey-color-second;
                border-bottom: 1px solid $grey-color-second;
                @include fontStyle($text-color, $middle-heading, 20px, 700);
                text-align: center;
                background: transparent;
                position: relative;
                top: 42%;
                left: 2.5%;
            }

            &.overflow {
                overflow: unset;
            }

            @include xl {
                height: 100%;
                width: 112%;
            }

            @media only screen and (min-width: 1600px) {
                // height: 68vh;
                width: 118%;
            }
        }
    }
}

// progress uploading files 
form.files {
    @include flex(column, baseline, space-between, wrap);
    height: 100%;

    @include xl {
        height: calc(27rem - 65px);
        flex-direction: row;
    }

    @media only screen and (min-width: 1600px) {
        align-items: flex-start;
        height: calc(100% - 100px);
    }

    .red {
        filter: invert(61%) sepia(43%) saturate(7457%) hue-rotate(329deg) brightness(94%) contrast(93%);
    }
    .green {
        filter: invert(63%) sepia(46%) saturate(372%) hue-rotate(124deg) brightness(87%) contrast(84%);
    }

    .input-wrapper {
        // @include flex(column);
        // position: relative;
        // top: 10vh;
        // width: 100%;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-content: end;
        position: absolute;
        bottom: 0;
        @include xl {
            bottom: 20px;
        }
        .form-group {
            position: relative;
            width: 100%;
            border-top: 1px solid $grey-color-second;
            &:last-child {
                border-bottom: 1px solid $grey-color-second;
            }
            &>input {
                width: 100%;
                height: 52px;
                background: transparent;
                padding: 0 8%;
                @include fontStyle($text-color, $middle-heading, 20px, 400);
                
                &::placeholder {
                    @include fontStyle($text-color, $middle-heading, 20px, 400);
                }
            }
            &>img {
                width: 24px;
                height: 24px;
                position: absolute;
                right: 7%;
                top: 14px;
            }
        }
    }

    .setting-btn {
        @include flex(row);
        gap: 16px;
        width: 100%;

        @include xl {
            flex-wrap: wrap;
        }

        &>button {
            width: 39%;
            &.full-btn {
                width: calc(100% - 60px);
            }

            @include xl {
                &:nth-child(2) {
                    width: 100%;
                    order: -1;
                }
                &:nth-child(3) {
                    width: 82%;
                    order: 1;
                }
                &:nth-child(1) {
                    order: 2;
                }
            }
        }

        .circle {
            width: 44px;
            padding-bottom: 8px;
        }
    }

    &>div{
        width: 100%;
        &>label {
            @include fontStyle($text-color, $middle-heading, 20px, 700);
            position: relative;
            bottom: -3px;
            display: block;
            text-align: center;
            margin-bottom: 12px;
    
            &>input[type="file"] {
                display: none;
            }
    
            span {
                position: relative;
                top: 5px;
            }
        }
    }

    &>a {
        @include fontStyle($text-color, $middle-heading, 20px, 700);
        position: relative;

        @include xl {
            margin-bottom: 10px;
            margin-top: 10px;
        }

        &::after {
            content: '';
            width: 24px;
            height: 24px;
            // background: url('/svg/upload.svg') no-repeat center center;
            display: block;
            position: absolute;
            left: -32px;
            top: -4px;
        }
    }

    article {
        @include flex(column, center, flex-start);
        position: relative;
        gap: 16px;
        width: 100%;
        height: 71%;
        margin-top: 1rem;

        @include xl {
            height: calc(100% - 100px);
            max-height: 400px;
        }

        .files-group {
            width: 111%;
            height: 40vh;
            overflow-x: hidden;
            overflow-y: scroll;
            position: relative;

            &.overflow {
                overflow: unset;
            }

            @include xl {
                height: 100%;
            }

            @media only screen and (min-width: 1600px) {
                height: 69vh;
                width: 118%;
            }

            .file-item {
                @include flex(row, center, space-between);
                gap: 10px;
                border-top: 1px solid $grey-color-second;
                margin-bottom: 12px;
                padding: 0 7%;
                padding-top: 12px;

                &>img {
                    width: 40px;
                    height: 40px;
                    background: $second-color;
                    border-radius: 50%;
                    object-fit: none;
                }

                .files-info {
                    @include flex(column);
                    width: calc(100% - 80px);
                    h2 {
                        @include fontStyle($text-color, $middle-heading, 20px, 400);

                        &>img {
                            width: 12px;
                        }
                    }
                    span {
                        @include fontStyle($grey-color, $middle-heading, 20px, 400);
                    }
                }

                .stop-upload {
                    cursor: pointer;
                    width: 20px;
                    &>img {
                        width: 24px;
                    }
                }
            }
        }
    }
}

.files-wrapper {
    @include flex(row, flex-start, flex-start, wrap);
    align-content: flex-start;
    gap: 16px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2px;
    @include xl {
        flex-flow: row wrap;
        overflow-x: hidden;
        overflow-y: scroll;
        gap: 8px;
        height: calc(100% - 110px);
    }
    .item {
        @include flex(row, center, space-between);
        width: calc(25% - 12px);
        height: 72px;
        background: $bg-grey;
        border-radius: 8px;
        transition: all 0.5s;
        padding: 0 16px;
        gap: 16px;

        @include xl {
            width: 100%;
            gap: 8px;
        }

        &:hover {
            outline: 2px solid $primary-color;
        }

        &>img {
            width: 40px;
            height: 40px;
            background: rgba(216, 27, 35, 0.12);
            border-radius: 50%;
            object-fit: scale-down;
        }

        .item-info {
            @include flex(column);
            width: calc(100% - 94px);
            &>h2 {
                @include fontStyle($text-color, $middle-heading, 20px, 400);
            }
            &>span {
                @include fontStyle($grey-color, $middle-heading, 20px, 400);
            }
        }
    }
}

button {
    width: 100%;
    height: 44px;
    background: $primary-color;
    border-radius: 100px;
    @include fontStyle($main-white, $middle-heading, 20px, 700);
    border: none;
    outline: none;
    cursor: pointer;

    &.disabled {
        background: $primary-disable;
        pointer-events: none;
    }
}

.description {
    @include fontStyle($text-color, $middle-heading, 20px, 400);
}

.alert-popap.password {
    button {
        @media(max-width: 991px) {
            margin-top: 20px;
        }
    }
}