*,
*::before,
*::after {
    margin: 0px;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-size: $medium-heading;
    font-weight: normal;
    font-family: $red-hat, $san-serif;
    @include flex(column);
    height: 100vh;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

input,
textarea {
    border: none;
    outline: none;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 4px;
    position: relative;
    right: -20px;
}
 
::-webkit-scrollbar-track {
    background: transparent;
}
 
::-webkit-scrollbar-thumb {
    background: #0C1920;
    border-radius: 2px;
}

.circle-small {
    font-size: 6px;
    position: relative;
    bottom: 2px;
    margin: 0 1px;
}

@include xl {
    .mobile-hide {
        display: none !important;
    }

    .mobile-fixed {
        height: 100% !important;
    }
}

@include xl {
    .uploading {
        .progress {
            .right-title.desctop-hide {
                position: absolute;
                bottom: 25px;
                padding: 0 20px
            }
        }
    }
}


.right-title {
    @include flex(row-reverse, center, space-between);
    gap: 60px;
    @include xl {
        gap: 16px;
    }
    .download-btn {
        white-space: nowrap;
        margin-left: -15px;
        @include xl {
            margin-left: 0;
        }
    }
    &.desctop-hide {
        display: none;
    }
    @include xl {
        &.desctop-hide {
            display: flex;
        }
    }
    @include xl {
        flex-flow: row wrap;
        width: 100%;
        justify-content: center;
    }
    a {
        @include fontStyle($text-color, $middle-heading, 20px, 700);
        white-space: nowrap;
        .text {
            margin-left: 9px;
        }
        &.settings {
            &::before {
                content: '';
                width: 24px;
                height: 24px;
                display: inline-block;
                // background: url('../svg/settings.svg') center no-repeat;
                position: relative;
                top: 7px;
                right: 10px;
                @include xl {
                    top: 0px;
                    right: 1px;
                    filter: invert(100%) sepia(3%) saturate(13%) hue-rotate(81deg) brightness(106%) contrast(106%);
                }
            }
        }
        &.new-files {
            position: relative;
            &::before {
                content: '';
                width: 1px;
                height: 44px;
                background: $grey-color-second;
                display: block;
                position: absolute;
                left: -30px;
                top: -5px;
                @include xl {
                    display: none;
                }
            }
        }
        @include xl {
            &.new-files {
                order: -1;
                &::after {
                    display: none;
                }
            }
            &>span {
                display: none;
            }
            &.settings {
                width: 44px;
                height: 44px;
                border-radius: 50%;
                background: #50A4CD;
                @include flex(row, center, center);
            }
        }
    }
    button {
        @include fontStyle($main-white, $middle-heading, 20px, 700);
        // width: 269px;
        padding: 0 24px;
        @include xl {
            width: calc(100% - 60px);
            padding: 0;
        }
        &>span {
            @include fontStyle($main-white, $small-heading, 20px, 400);
        }
        &>img {
            width: 20px;
            position: relative;
            top: 2px;
            right: 7px;
            filter: invert(100%) sepia(3%) saturate(13%) hue-rotate(81deg) brightness(106%) contrast(106%);
        }
    }
}

.swiper {
    height: 100%;
}

.swiper-pagination {
    padding: 8px 12px;
    width: auto !important;
    left: 50% !important;
    transform: translate(-50%, -10px) !important;
    height: 24px;
    background: #0C1920;
    border-radius: 100px;
    display: flex;
    align-items: center;
}

.swiper-pagination-bullet-active {
    background-color: #0c1920 !important;
    position: relative;
    .circle {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: stroke 450s ease-out forwards;
    }

    @keyframes stroke {
        to {
            stroke-dashoffset: 0;
        }
    }
}

.swiper-pagination-bullet {
    position: relative;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
    svg {
        position: absolute;
        top: 0;
        left: 0;
    }
}
