// For Small Devices (Mobile Devices)
@mixin sm{
    @media only screen and (min-width: $screen-sm){
        @content;
    }
}

// For Medium Devices (iPads)
@mixin md{
    @media only screen and (min-width: $screen-md){
        @content;
    }
}

// For Large Devices (Tablets, Small screens)
@mixin lg{
    @media only screen and (min-width: $screen-lg){
        @content;
    }
}

// Extra Large Devices (Desktops, laptops)
@mixin xl{
    @media only screen and (max-width: $screen-xl){
        @content;
    }
}