

main {
    @include flex();
    height: calc(100vh - 104px);
    padding: 20px 48px 40px 48px;
    gap: 16px;
    background-color: $bg-grey;

    @include xl {
        padding: 0;
        gap: 0;
    }

    .upload-wrapper {
        width: 26rem;
        background: $bg-grey-second;
        border-radius: 8px;
        padding: 32px;

        @include xl {
            width: 100%;
            border-radius: 0;
            padding: 20px;
            height: calc(100vh - 104px);
        }

        &.full {
            width: 100%;
            padding: 0 32px 32px;
        }

        .upload-outer {
            @include flex(column);
            height: 100%;
            gap: 16px;
            justify-content: space-between;
            &.full-height {
                height: 100%;
            }

            @media only screen and (min-width: 1600px) {
                height: 100%;
            }

            @include xl {
                // height: 86%;
                height: calc(100% - 100px);

                &.full-height {
                    height: 100%;
                }
            }

            .upload-header {
                @include flex(row, center, space-between);
                width: 100%;
                height: 90px;
                border-bottom: 1px solid $grey-color-second;

                @include xl {
                    flex-direction: column;
                    justify-content: center;
                    border-bottom: unset;
                }

                .left-title {
                    @include flex(row, center, center);
                    gap: 16px;
                    @include xl {
                        @include flex(column, flex-start, flex-start);
                        width: 100%;
                        gap: 4px;
                        margin-top: 15px;
                    }
                    h2 {
                        @include fontStyle($text-color, $large-heading, 26px, 700);
                    }
                    h3 {
                        @include fontStyle($text-color, $middle-heading, 20px, 400);
                    }
                }
            }

            .upload-content {
                width: 100%;
                height: calc(100% - 110px);
                @include flex(row, center, center, wrap);
                gap: 16px;
                @include xl {
                    overflow-x: inherit;
                    overflow-y: inherit;
                }

                &.uploading {
                    display: flex;
                    justify-content: space-between;
                    overflow: inherit;
                    height: calc(100% - 106px);
                    &.progress, &.empty {
                        justify-content: center;
                    }
                    @media only screen and (max-width: 992px) {
                        flex-wrap: wrap;
                    }
                    .upload-section {
                        width: 25%;
                        height: 100%;
                        @media only screen and (max-width: 992px) {
                            width: 100%;
                        }
                        article.group-form {
                            // height: calc(100vh - 388px);
                        }
                        .files-group {
                            width: 100%;
                            height: 47.5vh;
                            @media only screen and (max-height: 700px) {
                                height: 39.5vh;
                            }
                            // overflow: hidden;
                            @media only screen and (min-width: 1440px) {
                                height: 50.5vh;
                                width: 100%;
                            }
                            @media only screen and (min-width: 1600px) {
                                height: 64.5vh;
                                width: 100%;
                            }
                        }
                        form {
                            align-items: center;
                        }
                        .files-group {
                            height: 100%;

                            @media only screen and (min-width: 1600px) {
                                height: 100%;
                            }
                        }
                    }
                    .files-wrapper {
                        width: calc(75% - 25px);
                        overflow-y: scroll;
                        overflow-x: hidden;
                        padding: 2px;
                        @media only screen and (max-width: 992px) {
                            width: 100%;
                        }
                        .item {
                            width: calc(33% - 9px);
                            @media only screen and (max-width: 1440px) {
                                width: calc(50% - 9px);
                            }
                            @media only screen and (max-width: 992px) {
                                width: 100%;
                            }
                        }
                    }

                    form.files {
                        height: 100%;
                        @media only screen and (min-width: 1600px) {
                            align-items: flex-start;
                            height: 100%;
                        }
                    }
                }

                &>form {
                    align-items: center;
                }
                &>.uploading {
                    width: 349px;
                }
                &>.verification {
                    width: 412px;
                    height: unset;

                    @include xl {
                        height: 100%;
                    }

                    label {
                        margin-left: 13%;
                        @include xl {
                            margin-left: 0;
                        }
                    }

                    p {
                        position: relative;
                        width: 100%;
                        top: -45px;
                        @include xl {
                            top: 0;
                        }
                    }
                }
            }

            .upload-title {
                @include flex(row, flex-start, space-between, wrap);

                span {
                    @include fontStyle($text-color, $medium-heading, 22px, 700);
                    cursor: pointer;
                }

                .btn-group {
                    @include flex(row);
                    gap: 16px;

                    button {
                        width: auto;
                        height: 36px;
                        padding: 8px 20px;
                        background: $primary-color;
                        border-radius: 100px;
                        @include fontStyle($main-white, $middle-heading, 20px, 700);
                        border: none;
                        outline: none;
                        cursor: pointer;

                        &.generate {
                            background: transparent;
                            border: 1px solid $primary-color;
                            color: $primary-color;
                        }
                    }
                }
            }

            h1 {
                @include fontStyle($text-color, $large-heading, 26px, 700);
                margin-bottom: 20px;
                &.mrg-small {
                    margin-bottom: 8px;
                }
            }

            .upload-card {
                @include flex(column, center, center);
                padding: 0px 67px;
                gap: 12px;
                width: 100%;
                height: 50%;
                border: 1px solid $grey-color-second;
                border-radius: 8px;
                transition: all 0.5s;
                cursor: pointer;

                &>img {
                    width: 56px;
                    height: 56px;
                    background: $primary-color;
                    object-fit: scale-down;
                    border-radius: 50%;
                    // filter: invert(100%) sepia(3%) saturate(13%) hue-rotate(81deg) brightness(106%) contrast(106%);
                    transition: all 0.5s;
                    &.white {
                        display: block;
                    }
                    &.blue {
                        display: none;
                    }
                }

                &>h2 {
                    @include fontStyle($text-color, $medium-heading, 22px, 700);
                    transition: all 0.5s;
                }

                &>p {
                    @include fontStyle($grey-color, $middle-heading, 20px, 400);
                    text-align: center;
                    transition: all 0.5s;
                }

                &:hover {
                    background: $primary-color;
                    .white {
                        display: none;
                    }
                    .blue {
                        display: block;
                    }

                    &>h2,
                    &>p {
                        color: $main-white;
                    }
                    &>img {
                        background: $main-white;
                    }
                }
            }
        }
    }

    .slider-wrapper {
        width: calc(100% - 26rem);
        background-color: $bg-grey-second;
        border-radius: 8px;
        overflow: hidden;
        position: relative;

        @include xl {
            display: none;
        }

        &::before {
            content: '';
            background: rgba(12, 25, 32, 0.24);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }

        &>img {
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 992px) {
    main.uploading {
        height: 100%;
        &.progress {
            height: calc(100vh - 104px)
        }

        .upload-wrapper {
            height: 100%;

            .files-wrapper.uploading {
                height: calc(100vh - 100px);
            }
        }
    }
}


.slider-wrapper {
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

span.icon {
    width: 24px;
    height: 24px;
    display: inline-block !important;
    position: relative;
    top: 7px;
    right: 10px;
}

.download-page .verification {
    label {
        margin-left: 0 !important;
    }
    .group-form .write-pass {
        left: -145px;
    }
}

.no-wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.type-icon {
    width: 40px;
    height: 40px;
    background: #01723A1F;
    border-radius: 50%;
    padding: 10px;
    display: flex;
}

.action {
    cursor: pointer;
}