@mixin font-variant($color: false, $size: false, $height: false, $weight: false) {
    @if $color {
        color: $color;
    }
  
    @if $size {
        font-size: $size;
    }
  
    @if $height {
        line-height: $height;
    }
  
    @if $weight {
        font-weight: $weight;
    }
}
  
@mixin fontStyle($color: false, $size: false, $height: false, $weight: false) {
    @include font-variant($color: $color, $size: $size, $height: $height, $weight: $weight);
}
  