header {
    width: 100%;
    height: 104px;
    background-color: $bg-grey;
    @include flex(row, center, space-between);
    padding: 0 48px;

    @include xl{
        padding: 0 20px;
    }

    .works {
        font-weight: 700;
        font-size: $middle-heading;
        line-height: 20px;
        color: $text-color;
        margin-right: 12px;
    }
}
