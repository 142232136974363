.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(12, 25, 32, 0.24);

    @include xl {
        overflow: hidden;
    }
    @include flex(row, center, center);

    form.verification {
        @include flex(column, unset, unset);
        width: 100%;
    }

    &.hide {
        display: none;
    }

    .alert-popap {
        @include flex(row, flex-start, flex-start, wrap);
        padding: 20px;
        width: 365px;
        height: 154px;
        background: $main-white;
        border-radius: 8px;
        position: relative;

        h2 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 300px;
        }

        @include xl {
            height: auto;
        }

        &.verify-download {
            height: auto;
            p {
                margin: 20px 0;
            }
            form {
                width: 100%;
            }
        }

        .desctop-hidden {
            display: none;
        }

        @include xl {
            .mobile-hide {
                display: none;
            }
            .mobile-mg {
                margin: 12px 0;
            }
            .modal-header {
                width: 100%;
                height: 62px;
                position: relative;
                top: -10px;
                @include flex(row, center, space-between);
                &.desctop-hidden {
                    display: flex;
                }
                &::after {
                    content: '';
                    height: 1px;
                    width: 120%;
                    background: $bg-grey-second;
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: -40px;
                }
            }
        }

        @include xl {
            width: 100%;
            border-radius: 24px 24px 0px 0px;
            position: absolute;
            bottom: 0;

            &.message {
                // height: 227px;

                .group-btn {
                    flex-direction: column-reverse;
                    gap: 0;
                    &>button {
                        width: 100%;
                    }
                }
            }
        }

        &.password {
            width: 412px;
            height: 199px;
            @include xl {
                width: 100%;
                height: 235px;
            }
        }

        &.success {
            width: 365px;
            height: 250px;
            @include xl {
                width: 100%;
                height: 340px;
            }   
        }

        &.dates {
            width: 198px;
            height: 183px;
            @include xl {
                width: 100%;
                height: 203px;
            } 
            .date {
                @include flex(row, center, space-between);
                width: 100%;
                @include xl {
                    padding-right: 20px;
                } 
                &>label {
                    @include fontStyle($text-color, $middle-heading, 20px, 400);
                    width: 100%;
                    position: relative;
                }
                &>input {
                    width: 15px;
                    height: 15px;
                }
                &>input[type="radio"] {
                    display: none;
                }
                &>input[type="radio"] + *::after {
                    content: "";
                    display: inline-block;
                    vertical-align: bottom;
                    width: 1rem;
                    height: 1rem;
                    margin-right: 0.3rem;
                    border-radius: 50%;
                    border-style: solid;
                    border-width: 0.1rem;
                    border-color: $text-color;
                    position: absolute;
                    right: 0;
                }
                &>input[type="radio"]:checked + * {
                    color: $primary-color;
                }
                &>input[type="radio"]:checked + *::after {
                    background: radial-gradient($primary-color 0%, $primary-color 40%, transparent 50%, transparent);
                    border-color: $primary-color;
                }
            }
        }

        &.how {
            width: 400px;
            height: 100%;
            border-radius: 0;
            position: absolute;
            overflow: auto;
            right: 0;
            display: block;
            &>h2 {
                font-size: $large-heading;
                @include xl {
                    font-size: $medium-heading;
                }
            }
            &>p {
                @include fontStyle($grey-color, $middle-heading, 20px, 400);
                margin-top: 25px;
            }
            &>ul {
                margin: 25px 0 25px 30px;
                list-style-type: disc;
            }
            @include xl {
                width: 100%;
                height: 82%;
                border-radius: 24px 24px 0px 0px;
            }
        }

        &.settings {
            width: 270px;
            height: 140px;
            position: relative;
            bottom: 65px;
            left: 34px;
            @include xl {
                height: 203px;
            }
            .setting {
                @include flex(row, center, space-between);
                width: 100%;
                @include xl {
                    padding-right: 20px;
                } 
                &>a {
                    @include fontStyle($text-color, $middle-heading, 20px, 400);
                }
                &>img {
                    position: unset;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }
            }
            @include xl {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
            } 
        }

        &.files-settings {
            position: absolute;
            left: 80px;
            bottom: 132px;
            @include xl {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }

        .circle-success {
            @include flex(row, center, center);
            width: 80px;
            height: 80px;
            background: #E4F1F0;
            border: 1px solid #59A89F;
            border-radius: 50%;
            margin: 0 auto;
            margin-top: 40px;
            margin-bottom: 16px;

            &>img {
                position: unset;
                filter: invert(66%) sepia(3%) saturate(5578%) hue-rotate(124deg) brightness(94%) contrast(75%);
            }
        }

        h2.success {
            @include fontStyle($text-color, $middle-heading, 20px, 400);
            text-align: center;
            margin-bottom: 40px;
        }

        img {
            width: 24px;
            position: absolute;
            right: 17px;
            top: 20px;
        }

        h2 {
            @include fontStyle($text-color, $medium-heading, 22px, 700);
        }

        p {
            @include fontStyle($grey-color, $middle-heading, 20px, 400);
        }

        .group-btn {
            @include flex(row, end, center);
            gap: 17px;
            align-self: end;
            width: 100%;

            button {
                @include fontStyle($primary-color, $middle-heading, 20px, 700);
                border: 0;
                outline: none;
                cursor: pointer;
                width: 154px;
                height: 44px;
                background: transparent;
                border-radius: 100px;
            }

            .cancel {
                color: #50A4CD;
            }

            .success {
                color: #FFFFFF;
                background: #E75151;
            }
        }
    }
}