$primary-color: #50A4CD;
$primary-disable: #8EBED6;
$main-white: #FFFFFF;
$text-color: #0C1920;
$bg-grey: #F6F8FA;
$bg-grey-second: #E3E7EC;
$transparent: rgba(12, 25, 32, 0.24);
$grey-color: #71777A;
$grey-color-second: #CED3D9;
$gradient: linear-gradient(90deg, #E1B10D 0%, #D25570 100%);
$success: #59A89F;
$error: #E75151;
$error-second: #D75555;
$second-color: #F1D9CE;
$background: #4F9991;
$disable-color: #8CBED7;
$hover-colod: #4791B5;
$adition-one: #E4F1F0;
$adition-two: #F8C30A;
$adition-three: #E26B85;
$adition-four: #86C3D6;
$adition-five: #DCEDF5;